var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part" },
          [
            _c(
              "div",
              { staticClass: "top-page-header page-header-with-banner" },
              [
                _c("div", { staticClass: "head-title top-space" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.$t("sr.pageHeading")))]),
                ]),
              ]
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg extra-padding",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0 pt-0 pb-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sub-heading-with-btn d-flex flex-wrap align-baseline mb-0",
                                },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "black-text " },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("sr.pageHeading")) + " "
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            "max-width": "300px",
                                            color: "primarydark",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "greyxdark--text",
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("sr.srDesc"))),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-search-with-btn w-md-100 ",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          width: "200",
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.search"),
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary ml-3",
                                            depressed: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openCreateDialog()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("sr.createSR")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-0 pa-0 mt-4 table-style-3",
                            attrs: {
                              "items-per-page": 10,
                              headers: _vm.srHeaders,
                              items: _vm.srItems || [],
                              search: _vm.search,
                              "no-data-text": _vm.$t("common.noDataText"),
                              loading: _vm.$apollo.queries.getSR.loading,
                              "footer-props": {
                                itemsPerPageText: _vm.$t(
                                  "common.dataTableFooterProps.itemsPerPageText"
                                ),
                                itemsPerPageAllText: _vm.$t(
                                  "common.dataTableFooterProps.itemsPerPageAllText"
                                ),
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.sno",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _vm._v(" " + _vm._s(index + 1) + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "item.reportName",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.reportName.toUpperCase()
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.reportFor",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      item.reportFor === 0
                                        ? [_vm._v("ORG(s)")]
                                        : _vm._e(),
                                      item.reportFor === 1
                                        ? [_vm._v("APP(s)")]
                                        : _vm._e(),
                                      item.reportFor === 2
                                        ? [_vm._v("CAMPAIGN(s)")]
                                        : _vm._e(),
                                      item.reportFor === 3
                                        ? [_vm._v("ADGROUP(s)")]
                                        : _vm._e(),
                                      item.reportFor === 4
                                        ? [_vm._v("KEYWORD(s)")]
                                        : _vm._e(),
                                      item.reportFor === 5
                                        ? [_vm._v("Impression share")]
                                        : _vm._e(),
                                      item.reportFor === 7
                                        ? [_vm._v("GOAL(s)")]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.repeatType",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      item.repeatType === 99
                                        ? [_vm._v("NO")]
                                        : _vm._e(),
                                      item.repeatType === 0
                                        ? [_vm._v("CUSTOM")]
                                        : _vm._e(),
                                      item.repeatType === 1
                                        ? [_vm._v("DAILY")]
                                        : _vm._e(),
                                      item.repeatType === 7
                                        ? [_vm._v("WEEKLY")]
                                        : _vm._e(),
                                      item.repeatType === 30
                                        ? [_vm._v("MONTHLY")]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.hoursLocal",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._l(item.hoursLocal, function (hour) {
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "greylight--text",
                                                              },
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.timeZone
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Time zone when the report will be sent. Data within the report will be UTC"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" " + _vm._s(hour) + " : 00 "),
                                        ]
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.daysLocal",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      item.repeatType === 1
                                        ? _c("v-chip", [_vm._v("ALL")])
                                        : _vm._l(
                                            item.daysLocal,
                                            function (day, i) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: i,
                                                  attrs: { small: "" },
                                                },
                                                [
                                                  day === 0
                                                    ? [_vm._v("SUN")]
                                                    : _vm._e(),
                                                  day === 1
                                                    ? [_vm._v("MON")]
                                                    : _vm._e(),
                                                  day === 2
                                                    ? [_vm._v("TUE")]
                                                    : _vm._e(),
                                                  day === 3
                                                    ? [_vm._v("WED")]
                                                    : _vm._e(),
                                                  day === 4
                                                    ? [_vm._v("THU")]
                                                    : _vm._e(),
                                                  day === 5
                                                    ? [_vm._v("FRI")]
                                                    : _vm._e(),
                                                  day === 6
                                                    ? [_vm._v("SAT")]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.action",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "no-m-no-p",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-2 rounded-circle",
                                                  attrs: {
                                                    icon: "",
                                                    dark: "",
                                                    elevation: "0",
                                                    small: "",
                                                    color: "primary",
                                                    title: "Delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.askConfirm(
                                                        item._id,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [
                                                      _vm._v(
                                                        " mdi-delete-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
              ],
              2
            ),
            _c("CreateSR", {
              attrs: { createSRData: _vm.createSRDataFrom },
              on: {
                emitSRSaved: function ($event) {
                  return _vm.$apollo.queries.getSR.refetch()
                },
              },
            }),
            _c("DecisionAlert", {
              attrs: {
                DecisionAlert: _vm.decisionAlertFlag,
                DecisionAlertTitle: _vm.decisionAlertTitle,
                DecisionAlertMessage: _vm.decisionAlertMessage,
                DecisionAlertIcon: _vm.decisionAlertIcon,
                DecisionAlertData: _vm.decisionAlertData,
              },
              on: {
                emitDecisionAlertCancelClicked: function ($event) {
                  _vm.decisionAlertFlag = false
                },
                emitDecisionAlertOkClicked: function ($event) {
                  return _vm.decisionOkAction($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }