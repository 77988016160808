

































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import {
  SAVE_SCHEDULE_REPORT
} from 'src/utils/apollo-mutations';
import { COUNTRIES_FOR_APP, GET_GOALS } from 'src/utils/apollo-queries';
import * as moment from 'moment';
import * as moment1 from 'moment-timezone';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import DecisionAlert from '../DecisionAlert.vue';
import SelectCampaign from '../campaign/SelectCampaign.vue';
import SelectAdGroup from '../keywords/SelectAdGroup.vue';
import SelectApps from '../arrules/SelectApps.vue';
import SelectKeywords from '../arrules/SelectKeywords1.vue';
import { GET_CAMPAIGN_GROUPS } from 'src/utils/apollo-queries';
import {
  GET_SLACKS,
  GET_EMAILS,
} from '../../utils/arrules-queries';

import { getOwnApps, updateCLF } from '../../utils/util'
import { COUNTRIES } from 'src/utils/constants';
import moment from 'moment';
import DuplicateEmailComboboxService from "@services/component-services/dulicate-email-combobox.service";

export default Vue.extend({
  name : 'CreateSR',
  components: {
    SelectCampaign,
    SelectAdGroup,
    SelectApps,
    DecisionAlert,
    SelectKeywords,
  },
  data() {
    return {
      reportTimePeriod: 28,
      includeDateColumn:false,
      selectedRepeatType: null,
      selectedReportFor: null,
      days: [], 
      rangePicker: false,
      filterDateRangeText: moment().format('YYYY-MM-DD'),
      filterDateRangeModel: [],
      maxDate: new Date().toISOString().split("T")[0],

      localTime: '',
      timeZone: '',
      ruleRangePicker: false,
      ruleDateRangelModel: [],
      Countries: null,
      appCountries: [],
      decisionAlertFlag: false,
      impShare: false,
      impShareFields: {
        app: null,
        granularity: null,
        countries: [],
        ruleDateRange: [],
        period: null,
      },
      searchInput: null,
      appsList: [],
      granularityList: ['DAILY', 'WEEKLY'],
      periodList: [
        { value: 'LAST_WEEK', text: 'Last Week' },
        { value: 'LAST_2_WEEKS', text: 'Last 2 Weeks' },
        { value: 'LAST_4_WEEKS', text: 'Last 4 Weeks' }
      ],
      decisionAlertMessage: '',
      decisionAlertIcon: 2,
      decisionAlertData: {},
      dialogSR: false,
      existingReportNames: [],
      createSRForm: true,
      reportName: 'SR-'+Date.now().toString(),
      emails: [],
      emailList: [],
      reportLimit: [
        { text: 'Show All', value: 0 },
        { text: 3, value: 3 },
        { text: 5, value: 5 },
        { text: 10, value: 10 },
      ],
      reportLimitSelected: 0,   
      externalEmails: [],
      slacks: [],
      slackChannelList : [],
      emailRules: [
        v => !!v || this.$t('createCampaignRules.emailReq'),
        v => v.length > 0 || this.$t('sr.emailWarning'),
      ],
      csvEmailRules: [        
        v => !(/\s/.test(v)) || this.$t('sr.emailNoSpaces'),
        v =>
          /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(
            v
          ) || this.$t('createAR.enterEmail'),
      ],
      selectedReportFor: 9,
      selectedRepeatType: 99,
      selectedReportDay: new Date(Number(moment().utc())).getDay(),
      selectedDays : [new Date(Number(moment().utc())).getDay()],
      selectedReportTime: new Date(Number(moment().utc())).getMinutes() < 45 ? new Date(Number(moment().utc())).getHours() + 1 : new Date(Number(moment().utc())).getHours() + 2,
      chooseCampaigns: {},
      chooseAdgroups: {},
      chooseApps: {},
      chooseKeywords: {},
      orgs: [],
      campaigns: [],
      adgroups: [],
      apps: [],
      keywords: [],
      goals: [],
      orgList: [],
      goalList: [],
      filteredGoalList: [],
      days : [
        { text:'mon', id: 1, status : false, value : 'M'},
        { text:'tue', id: 2, status : false, value : 'T'},
        { text:'wed', id: 3, status : false, value : 'W'},
        { text:'thu', id: 4, status : false, value : 'T'},
        { text:'fri', id: 5, status : false, value : 'F'},
        { text:'sat', id: 6, status : false, value : 'S'},
        { text:'sun', id: 0, status : false, value : 'S'},
      ],
      comboboxService: new DuplicateEmailComboboxService({
        component : this,
        externalEmailPropertyName : "externalEmails",
        mainEmailPropertyName : "emails"
      }),
    }
  },
  created() {
    this.selectPreset('TODAY');
  }, 
  computed: {
    periodListFull() {
      return [...this.periodList]
    },
    ruleDateRangeText() {
      if(!this.impShareFields.ruleDateRange.length) return '';
      if (this.impShareFields.ruleDateRange[0] > this.impShareFields.ruleDateRange[1]) {
        const maxDate = this.impShareFields.ruleDateRange[0];
        this.impShareFields.ruleDateRange[0] = this.impShareFields.ruleDateRange[1];
        this.impShareFields.ruleDateRange[1] = maxDate;
      }
      return this.impShareFields.ruleDateRange.join(' - ');
    },
    decisionAlertTitle: {
      get(){return this.$t('sr.scheduleSaveWarning')}},
      
      dateSplitRange: {
        cache: false,
        get() {
          return [
            { text: 'Custom Range', value: 0 },
            { text: 'Yesterday', value: 1 },
            { text: 'Last Week', value: 7 },
            { text: 'Last 2 Weeks', value: 14 },
            { text: 'Last 4 Weeks', value: 28 }
          ]
        }
      },
      
    reportFors: {
      cache: false,
      get() {
        return [
          { for: this.$t('labels.org'), id: 0},
          { for: this.$t('labels.app'), id: 1},
          { for: this.$t('labels.camp'), id: 2},
          { for: this.$t('labels.adGroup'), id: 3},
          { for: this.$t('labels.key'), id: 4},
          { for: this.$t('labels.impShare'), id: 5}
        ];
      },
    },
    repeatTypes: {
      cache: false,
      get() {
        return [
          { type: this.$t('sr.noRepeat'), id: 99},
          // { type: this.$t('sr.custom'), id: 0},
          { type: this.$t('sr.daily'), id: 1},
          { type: this.$t('sr.weekly'), id: 7},
          // { type: this.$t('sr.monthly'), id: 30},
        ];
      },
    },
    reportDays: {
      cache: false,
      get() {
        return [          
          { day: this.$t('createAR.mon'), id: 1},
          { day: this.$t('createAR.tue'), id: 2},
          { day: this.$t('createAR.wed'), id: 3},
          { day: this.$t('createAR.thu'), id: 4},
          { day: this.$t('createAR.fri'), id: 5},
          { day: this.$t('createAR.sat'), id: 6},
          { day: this.$t('createAR.sun'), id: 0},
          // { day: this.$t('common.na'), id: 9},
        ];
      },
    },
    reportTimes: {
      cache: false,
      get() {
        return [
          { time: '00:00 - 01:00', id: 0},
          { time: '01:00 - 02:00', id: 1},
          { time: '02:00 - 03:00', id: 2},
          { time: '03:00 - 04:00', id: 3},
          { time: '04:00 - 05:00', id: 4},
          { time: '05:00 - 06:00', id: 5},
          { time: '06:00 - 07:00', id: 6},
          { time: '07:00 - 08:00', id: 7},
          { time: '08:00 - 09:00', id: 8},
          { time: '09:00 - 10:00', id: 9},
          { time: '10:00 - 11:00', id: 10},
          { time: '11:00 - 12:00', id: 11},
          { time: '12:00 - 13:00', id: 12},
          { time: '13:00 - 14:00', id: 13},
          { time: '14:00 - 15:00', id: 14},
          { time: '15:00 - 16:00', id: 15},
          { time: '16:00 - 17:00', id: 16},
          { time: '17:00 - 18:00', id: 17},
          { time: '18:00 - 19:00', id: 18},
          { time: '19:00 - 20:00', id: 19},
          { time: '20:00 - 21:00', id: 20},
          { time: '21:00 - 22:00', id: 21},
          { time: '22:00 - 23:00', id: 22},
          { time: '23:00 - 23:59', id: 23},
        ];
        // return [
        //   { time: '00:00', id: 0},
        //   { time: '01:00', id: 1},
        //   { time: '02:00', id: 2},
        //   { time: '03:00', id: 3},
        //   { time: '04:00', id: 4},
        //   { time: '05:00', id: 5},
        //   { time: '06:00', id: 6},
        //   { time: '07:00', id: 7},
        //   { time: '08:00', id: 8},
        //   { time: '09:00', id: 9},
        //   { time: '10:00', id: 10},
        //   { time: '11:00', id: 11},
        //   { time: '12:00', id: 12},
        //   { time: '13:00', id: 13},
        //   { time: '14:00', id: 14},
        //   { time: '15:00', id: 15},
        //   { time: '16:00', id: 16},
        //   { time: '17:00', id: 17},
        //   { time: '18:00', id: 18},
        //   { time: '19:00', id: 19},
        //   { time: '20:00', id: 20},
        //   { time: '21:00', id: 21},
        //   { time: '22:00', id: 22},
        //   { time: '23:00', id: 23},
        // ];
      },
    },
  },
  props: {
    createSRData: {
      type: Object,
      required: false,
    },
  },
  watch: {
    createSRData: function (data) {
      this.existingReportNames = data.existingReportNames
      this.reportName = 'SR-'+Date.now().toString()
      this.emails = []
      this.externalEmails = []
      this.reportLimitSelected=0
      this.selectedReportFor = 0
      this.selectedRepeatType = 99
      this.selectedReportDay = new Date(Number(moment().utc())).getDay()      
      this.evalToday()
      this.selectedReportTime = new Date(Number(moment().utc())).getMinutes() < 45 ? new Date(Number(moment().utc())).getHours() + 1 : new Date(Number(moment().utc())).getHours() + 2
      this.filteredGoalList = []
      this.goals = []
      this.orgs = []
      this.apps = []
      this.campaigns = []
      this.adgroups = []
      this.keywords = []
      this.dialogSR = true;
      
      this.impShareFields = {
        app: null,
        granularity: null,
        countries: [],
        ruleDateRange: [],
        period: null,
      }
    },
  },
  apollo: {
    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.impShareFields.app,
        };
      },
      skip() {
        return !this.impShareFields.app;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
  },
  methods: {
    emailSelectionChanged() {
      this.comboboxService.checkAndInitiateADuplicateRemovalInOtherEmail();
    },
    onModelValueChange(){
      this.comboboxService.checkForDuplicateRemovalAndNotifyError();
    },
    selectPreset(preset) {
      this.presetStatus = true;
      this.updatedDaysText = '';
      switch (preset) {
        case 'TODAY':
          this.filterDateRangeModel = [moment().utc().format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.today');
          break;
        case 'YESTERDAY':
          this.filterDateRangeModel = [moment().utc().subtract(1, 'days').format('YYYY-MM-DD'), moment().utc().subtract(1, 'days').format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.yesterday');
          break;
        case 'WEEKTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('week').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.weekToDate');
          break;
        case 'LASTWEEK':
          this.filterDateRangeModel = [moment().utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastWeek');
          break;
        case 'LASTSEVENDAYS':
          this.filterDateRangeModel = [moment().utc().subtract(6, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastSevenDays');
          break;
        case 'MONTHTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('month').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.monthToDate');
          break;
        case 'LASTMONTH':
          this.filterDateRangeModel = [moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastMonth');
          break;
        case 'LASTTHIRTYDAY':
          this.filterDateRangeModel = [moment().utc().subtract(29, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastThirtyDays');
          break;
      }
    },

    changePeriod() {
    if (this.filterDateRangeModel && this.filterDateRangeModel.length === 2) {
      this.filterDateRangeText = `${this.filterDateRangeModel[0]} - ${this.filterDateRangeModel[1]}`;

      if((moment(this.filterDateRangeModel[1]).diff(moment(this.filterDateRangeModel[0]), 'days') + 1) > 30) {
        return this.$notify.warning('Date range for scheduled report can not be more than 30 days apart.')
      }

    }
    this.rangePicker = false;
  },

    getLocalTime() {
      const localTime = new Date().toLocaleString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      });
      this.localTime = localTime;
    },  
    getTimeZone() {
      // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZone = moment1.tz.guess(); var abbr = moment1.tz(timeZone).format("z");
      this.timeZone = abbr;   
    },
    
    applyRuleDateRange() {
      const range = this.ruleDateRangelModel.sort();
      if((moment(range[1]).diff(moment(range[0]), 'days') + 1) > 30) {
        this.impShareFields.ruleDateRange = [];
        this.ruleDateRangelModel = [];
        return this.$notify.warning('Date range for impression share report can not be more than 30 days apart.')
      }
      this.impShareFields.ruleDateRange = range;
      this.ruleRangePicker = false;
    },

    clearRuleDateRange() {
      this.impShareFields.ruleDateRange = [];
      this.ruleDateRangelModel = [];
      this.ruleRangePicker = false;
    },
    setCountries(data) {
      if(data && data[0] && data[0].countryOrRegionCodes && data[0].countryOrRegionCodes.length > 0){
        const Countries = data[0].countryOrRegionCodes;
        for (const countryCode of Countries) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.appCountries.push(country);
          }
        }
      }
      }else{
        this.appCountries = this.Countries
      }
    },
    evalToday(){
      this.selectedDays = [new Date(Number(moment().utc())).getDay()]
      this.days.map(d => d.status = false)
      this.selectedDays.map(e => {
        this.days.map(d => {
          if(e === d.id){
            d.status = true
          }
        })
      })
    },
    clicked(e){
    },
    dayClicked(day){
      if(this.selectedRepeatType === 99){
        this.days.map(d => d.status = false)        
        this.selectedDays = []
        this.selectedDays.push(day.id)
        this.days.map(d => {
          if(day.id === d.id){
            d.status = true
          }
        })
      }else{
        if(day.status){
          this.selectedDays.push(day.id)
        }else{
          this.selectedDays = this.selectedDays.filter(e => e !== day.id)
        }
      }
    },
    repeatTypeChanged(e){
      switch (e) {
        // case 0:
        //   this.selectedDays = [new Date(Number(moment().utc())).getDay()]
        //   break;      
        case 1:
          this.selectedDays = [0,1,2,3,4,5,6]
          this.days.map(d => d.status = true)
          break;
        case 7:
        case 99:
          this.evalToday()
          break;
        // case 30:
        //   this.selectedDays = [9]
        //   break;     
        default:
          break;
      }
    },
    askConfirm() {
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        from: 'sr', 
        now: Date.now(),
      };
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      this.saveSR();
    },
    async saveSR(){
      this.fullscreenLoader = this.$loading.show();  
      let offsetInMinutes = (new Date().getTimezoneOffset())/60     
      // offsetInMinutes = +5.5
      let timeAterOffset = 0
      let daysOffset, nextRunAt = 0
      if(offsetInMinutes < 0) { 
        //final report time must be lesser than selected 15:00 -> 10:00, 01:00 -> prev day 20:00, 23:00 -> 18:00
        offsetInMinutes = Math.ceil(offsetInMinutes)        
        timeAterOffset = this.selectedReportTime + offsetInMinutes
        if(timeAterOffset < 0){
          timeAterOffset = 24 + timeAterOffset
          daysOffset = this.selectedDays.map(e => e == 0 ? 6 : e - 1 )
          nextRunAt = this.evalNextRun('-', 1)
        }else{
          daysOffset = this.selectedDays
          nextRunAt = this.evalNextRun('-', 0)
        }
      }else{ 
        //final report time must be greater than selected 15:00 -> 20:00, 01:00 -> 06:00, 23:00 -> next day 05:00
        offsetInMinutes = Math.ceil(offsetInMinutes)        
        timeAterOffset = this.selectedReportTime + offsetInMinutes
        if(timeAterOffset < 24){
          daysOffset = this.selectedDays
          nextRunAt = this.evalNextRun('+', 0)
        }else{
          timeAterOffset = timeAterOffset - 24
          daysOffset = this.selectedDays.map(e => e == 6 ? 0 : e + 1 )
          nextRunAt = this.evalNextRun('+', 1)
        }
      }          
      const emails = this.emails.concat(this.externalEmails)
      let dataRangePeriod = '';
      if(this.reportTimePeriod === 0){
        dataRangePeriod = [this.filterDateRangeModel[0], this.filterDateRangeModel[1]].sort();
      }
      const saveObj = {
        ...this.selectedReportFor === 5 && {
          impShareSettings: this.impShareFields,
        },
        reportForDays: this.reportTimePeriod,
        reportName: this.reportName,
        repeatType: this.selectedRepeatType,
        days: daysOffset,
        daysLocal: this.selectedDays,
        hours: [timeAterOffset],
        hoursLocal: [this.selectedReportTime],
        reportFor: this.selectedReportFor,
        emails: emails,
        reportLimit:this.reportLimitSelected,
        goals: this.goals.map(({datetime, createdBy, status, ...keepAttr}) => {return keepAttr}),
        slacks: this.slacks,
        nextRunAt : await nextRunAt,
        nextRunLocal: new Date(await nextRunAt).toString(),
        nextRunUTC: new Date(await nextRunAt).toUTCString(),
        dateRange: dataRangePeriod,
        includeDateColumn: this.includeDateColumn
      }
      let forList = []
      switch (this.selectedReportFor) {
        case 0:
          forList = this.orgs.map(e => {
                      return {
                        orgId : e.orgId,
                        orgName : e.orgName,
                        orgCurrency : e.currency || '',
                        orgTimeZone : e.timeZone || '',
                        orgIntegrationID : e.IntegrationID || '',
                        campName: "",
                        campaignId: 0,
                        appName: "",
                        adamId: 0,
                        adgroupName: "",
                        adgroupId: 0,
                        keywordName: "",
                        keywordId: 0
                      }
                    })
          break;
        case 1:
          forList = this.apps.map(e => {
                      return {
                        orgId : e.orgId,
                        orgName : e.orgName,
                        orgCurrency : e.orgCurrency || '',
                        orgTimeZone : e.orgTimeZone || '',
                        orgIntegrationID : e.orgIntegrationID || '',
                        campName: "",
                        campaignId: 0,
                        appName: e.appName,
                        adamId: e.adamId,
                        adgroupName: "",
                        adgroupId: 0,
                        keywordName: "",
                        keywordId: 0
                      }
                    })
          break;
        case 2:
          forList = this.campaigns
          break;
        case 3:
          forList = this.adgroups
          break;
        case 4:
          forList = this.keywords
          break;
        default:
          break;
      }
      saveObj.reportForList = forList
      const scheduledReportReq = {
        scheduledReport: saveObj
      };
      
      const result = await this.$apollo.mutate({
        mutation: SAVE_SCHEDULE_REPORT,
        variables: scheduledReportReq,
      }).catch((error) => {
        console.log(error)
        this.fullscreenLoader.hide();
        this.$notify.error(this.$t('sr.errorDuringSave'));
      })

      if (result.data.saveScheduledReport.code === 0) {
        this.$notify.success(this.$t('sr.scheduleSuccess'));
        this.dialogSR = false;
        this.$emit('emitSRSaved', true); 
        this.fullscreenLoader.hide();
        updateCLF('scheduledReports', this);        
      } else {
        this.fullscreenLoader.hide();
        this.$notify.error(this.$t('sr.errorDuringSave'));
      }
    },
    async evalNextRun(zone, corr){    
      let nextRunAt = 0, offBy = 0, d = 0, repeatCorr = 0
      switch (this.selectedRepeatType) {
        case 99 :{     
            d = this.selectedDays[0]
            if(d > new Date().getDay()) {
              offBy = d - new Date().getDay()
            }
            else if(d < new Date().getDay()) {
              offBy = 7 - (new Date().getDay() - d)
            }else{
              offBy = new Date().getDay() - d
              if(this.selectedReportTime > new Date().getHours()) {
                offBy = offBy
              }else{
                // offBy = offBy + 7
                offBy = offBy + 7 + repeatCorr
              } 
            }    
            console.log(offBy, d)        
            const n = new Date()
            const en = new Date(n.getFullYear(),n.getMonth(), n.getDate() + offBy, this.selectedReportTime, 1)
            nextRunAt = en.getTime()
          }
          break
        case 7 :{
            const allRuns = []
            this.selectedDays.map(d => {
              if(d > new Date().getDay()) {
                offBy = d - new Date().getDay()
              }
              else if(d < new Date().getDay()) {
                offBy = 7 - (new Date().getDay() - d)
              }else{
                offBy = new Date().getDay() - d
                if(this.selectedReportTime > new Date().getHours()) {
                  offBy = offBy
                }else{
                  // offBy = offBy + 7
                  offBy = offBy + 7 + repeatCorr
                } 
              }    
              console.log(offBy, d)        
              const n = new Date()
              const en = new Date(n.getFullYear(),n.getMonth(), n.getDate() + offBy, this.selectedReportTime, 1)
              nextRunAt = en.getTime()
              allRuns.push(nextRunAt)
            })
            nextRunAt = (allRuns.sort())[0]
          }
          break
        case 1 : {
            const higherDays = this.selectedDays.filter(d => d >= new Date().getDay())
            console.log(higherDays)
            d = (higherDays.sort())[0]
            if(d === 6) repeatCorr = -6
            if(d > new Date().getDay()) {
              offBy = d - new Date().getDay()
            }
            else if(d < new Date().getDay()) {
              offBy = 7 - (new Date().getDay() - d)
            }else{
              offBy = new Date().getDay() - d
              if(this.selectedReportTime > new Date().getHours()) {
                offBy = offBy
              }else{
                // offBy = offBy + 7
                offBy = offBy + 7 + repeatCorr
              } 
            }    
            console.log(offBy, d)        
            const n = new Date()
            const en = new Date(n.getFullYear(),n.getMonth(), n.getDate() + offBy, this.selectedReportTime, 1)
            nextRunAt = en.getTime()
          }
          break
        default: 
          break
      }
      return nextRunAt
    },
    orgChanged(e){
      
    },
    forChanged(e){
      this.filteredGoalList = []
      this.goals = []
      this.orgs = []
      this.apps = []
      this.campaigns = []
      this.adgroups = []
      this.keywords = []
      this.choosePop()
    },
    choosePop() {
      const from = this.selectedReportFor;
      if (from == 1) {
        this.chooseApps = {
          alreadySelectedAppIds : this.apps.map(a => a.adamId),
          now: Date.now(),
        };
      } else if (from == 2) {
        this.chooseCampaigns = {
          now: Date.now(),
        };
      } else if (from == 3) {
        this.chooseAdgroups = {
          now: Date.now(),
        };
      } else if (from == 4) {
        this.chooseKeywords = {
          now: Date.now(),
        };
      } else if(from == 5) {
        this.setupImpressionShare()
      } else {
          this.impShareFields = {
          app: null,
          granularity: null,
          countries: [],
          ruleDateRange: [],
          period: null,
        }
      }
    },
    async setupImpressionShare() {
      this.impShare = true;
      
      this.impShareFields = {
          app: null,
          granularity: null,
          countries: [],
          ruleDateRange: [],
          period: null,
      }
      await getOwnApps('createsr', this);
      
      this.appsList = LSS.ownApps;
    },
    chosenCampaigns(e){
      const campaignsAll = this.getNested(e, 'CAMPAIGN');
      let existingIds = this.campaigns.map(c => c.campaignId)
      const campaigns = campaignsAll.filter(c => !existingIds.includes(c.id))
      campaigns.map(g => {
          let cur = ''
          let tz = ''
          let iId = ''
          let on = ''
          this.orgList.forEach(org => {
            if(g.orgId === org.orgId){
              cur = org.currency || ''
              tz = org.timeZone || ''
              iId = org.IntegrationID || ''
              on = org.orgName
            }
          })
          this.campaigns.push({
            orgId : g.orgId,
            orgName : on,
            orgCurrency : cur,
            orgTimeZone : tz,
            orgIntegrationID : iId,
            campName: g.name,
            campaignId: g.id,
            appName: "",
            adamId: +g.adamId,
            adgroupName: "",
            adgroupId: 0,
            keywordName: "",
            keywordId: 0
          })
      })
      this.filterGoals(this.campaigns)
    },
    chosenAdgroups(e){
      const adgroupsAll = this.getNested(e?.data, 'ADGROUP');
      let existingIds = this.adgroups.map(a => a.adgroupId)
      const adgroups = adgroupsAll.filter(c => !existingIds.includes(c.id))
      adgroups.map(g => {
          let cur = ''
          let tz = ''
          let iId = ''
          let on = ''
          this.orgList.forEach(org => {
            if(g.orgId === org.orgId){
              cur = org.currency || ''
              tz = org.timeZone || ''
              iId = org.IntegrationID || ''
              on = org.orgName
            }
          })
          this.adgroups.push({
            orgId : g.orgId,
            orgName : on,
            orgCurrency : cur,
            orgTimeZone : tz,
            orgIntegrationID : iId,
            campName: "",
            campaignId: g.campaignId,
            appName: "",
            adamId: +g.adamId,
            adgroupName: g.name,
            adgroupId: g.id,
            keywordName: "",
            keywordId: 0
          })
      })
      this.filterGoals(this.adgroups)
    },
    chosenApps(apps){
      let existingIds = this.apps.map(a => a.adamId)
      apps.filter(app => {
        if(!existingIds.includes(app.adamId)){
          this.apps.push(app)
        }
      })
      this.apps.forEach((app,i) => {
        this.orgList.forEach(org => {
          if(app.orgId === org.orgId){
            this.apps[i].orgCurrency = org.currency || ''
            this.apps[i].orgTimeZone = org.timeZone || ''
            this.apps[i].orgIntegrationID = org.IntegrationID || ''
          }
        })
      });
      this.filterGoals(this.apps)
    },
    chosenKeywords(e){
      e.toEmit.map(g => {
          let cur = ''
          let tz = ''
          let iId = ''
          let on = ''
          this.orgList.forEach(org => {
            if(g.orgId === org.orgId){
              cur = org.currency || ''
              tz = org.timeZone || ''
              iId = org.IntegrationID || ''
              on = org.orgName
            }
          })
          this.keywords.push({
            orgId : g.orgId,
            orgName : on,
            orgCurrency : cur,
            orgTimeZone : tz,
            orgIntegrationID : iId,
            campName: "",
            campaignId: g.campId,
            appName: "",
            adamId: +g.adamId,
            adgroupName: "",
            adgroupId: 0,
            keywordName: g.name,
            keywordId: g.id
          })
      })
      this.filterGoals(this.keywords)
    },
    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups.map(ad => Object.assign(ad, {adamId : campaign.adamId})))
                  )
            )
          );
    },
    removeItemfromChosen(idx){      
      switch (this.selectedReportFor) {
        case 0:
          this.orgs = this.orgs.filter((e,i) => i !== idx)
          break;
        case 1:
          this.apps = this.apps.filter((e,i) => i !== idx)
          this.filterGoals(this.apps)
          break;
        case 2:
          this.campaigns = this.campaigns.filter((e,i) => i !== idx)
          this.filterGoals(this.campaigns)
          break;
        case 3:
          this.adgroups = this.adgroups.filter((e,i) => i !== idx)
          this.filterGoals(this.adgroups)
          break;
        case 4:
          this.keywords = this.keywords.filter((e,i) => i !== idx)
          this.filterGoals(this.keywords)
          break;
        case 7:
          this.goals = this.goals.filter((e,i) => i !== idx)
          break;
        default:
          break;
      }
    },
    getOrgs() {
      // this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_CAMPAIGN_GROUPS,
        })
        .then((res) => {
          //console.log(res.data);
          // this.fullscreenLoader.hide();
          this.orgList = res.data.getCampaignGroups
            ? res.data.getCampaignGroups
            : [];
          this.orgs.push(this.orgList[0]? this.orgList[0] : [])
        })
        .catch((error) => {
          console.log(error);
          // this.fullscreenLoader.hide();
        });
    },
    goalChanged(e){
    },
    filterGoals(list){
      const listAppIds = list.map(e => +e.adamId)
      this.filteredGoalList = this.goalList.filter(e => listAppIds.includes(e.appId))
    },
    getGoals() {
      // this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_GOALS,
        })
        .then((res) => { 
          // this.fullscreenLoader.hide();
          this.goalList = res.data.getGoals
        })
        .catch((error) => {
          console.log(error);
          // this.fullscreenLoader.hide();
        });
    },
    getSlacks() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_SLACKS,
        })
        .then((res) => {
          //console.log(res.data);
          this.slackChannelList = res.data.getSlacks;
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
    getEmails() {
      //this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_EMAILS,
        })
        .then((res) => {
          //console.log(res.data);
          this.emailList = res.data.getEmails ? res.data.getEmails.map(e => e.Email) : [];
          // this.emailList.push('hello@gmail.com')
          //this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          //this.fullscreenLoader.hide();
        });
    },
  },
  mounted() {
    this.getTimeZone();
    this.getLocalTime();
    this.Countries = COUNTRIES;
    this.emails = decodeToken(LSS.token).Email    
    this.getOrgs()
    this.getGoals()
    this.slackChannelList = [];
    this.getSlacks();
    this.emailList = [];
    this.getEmails();
  },
})
