













































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import {
  GET_SCHEDULE_REPORTS,
  DELETE_SCHEDULE_REPORT,
} from '../utils/apollo-queries';
import * as moment1 from 'moment-timezone';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import CreateSR from'../components/schedule-reports/CreateSR.vue';
import DecisionAlert from '../components/DecisionAlert.vue';

export default Vue.extend({
  components: {
    CreateSR,
    DecisionAlert,
  },
  data() {
    return {
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure to delete this scheduled report?',
      decisionAlertMessage: '',
      decisionAlertIcon: 1,
      decisionAlertData: {},
      dialogSR: false,
      createSRDataFrom: {},
      search: '',
      srItems: [],
      fullscreenLoader: null,
    }
  },
  apollo: {
    getSR: {
      query: GET_SCHEDULE_REPORTS,
      update(data) {
        this.srItems = data.getScheduledReports
      }
    }
  },
  computed: {
    srHeaders: {
      cache: false,
      get() {
        return [
          {
            text: 'S.No',
            align: 'start',
            value: 'sno',
          },
          {
            text: this.$t('goals.name'),
            align: 'start',
            value: 'reportName',
          },
          {
            text: this.$t('sr.for'),
            value: 'reportFor',
          },
          // {
          //   text: this.$t('sr.dataDays'),
          //   value: 'reportForDays',
          // },
          {
            text: this.$t('sr.type'),
            value: 'repeatType',
          },
          {
            text: this.$t('common.days'),
            value: 'daysLocal',
          },
          {
            text: this.$t('common.time'),
            value: 'hoursLocal',
          },
          // {
          //   text: this.$t('goals.createdOn'),
          //   value: 'createdAt',
          // },
          {
            text: this.$t('goals.action'),
            value: 'action',
            sortable: false,
          },
        ];
      },
    },
  },
  methods: {
    getTimeZone() {
      // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZone = moment1.tz.guess(); var abbr = moment1.tz(timeZone).format("z");
      this.timeZone = abbr;
      // console.log(abbr);      
    },
    askConfirm(id, from) {
      this.cgDbId = id
      this.decisionAlertTitle = this.$t('common.deleteConfirmText') + this.$t('sr.scheduleReport')
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        from: 'sr',
        now: Date.now(),
        data : {
          dbId : id,
          opId : from
        }
      };
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
        if(event.data){
            if(event.data.dbId && event.data.opId === 1){
                this.deleteSR(event.data.dbId)
            }
        }
    },
    async deleteSR(id){
      this.fullscreenLoader = this.$loading.show();
      const res = await this.$apollo
        .query({
          query: DELETE_SCHEDULE_REPORT,
          variables: {
            dbId: id,
          },
        })
        .catch((error) => {          
          this.fullscreenLoader.hide();
        });
        this.fullscreenLoader.hide();

        if (res.data.deleteScheduleReport.code === 0) {
          this.$notify.success('Scheduled Report deleted successfully');
          // await this.$apollo.queries.getCampGenList.refetch();
          this.srItems = this.srItems.filter(e => e._id != id)
        } else {
          this.$notify.error('Error while deleting scheduled report');
        }
    },
    openCreateDialog(){
        this.createSRDataFrom = {
            from : '',
            at: Date.now(),
            existingReportNames: this.srItems.map(e => e.reportName.toLowerCase())
        }
    },
    get(){
        // console.log('fetching...')
    },
    openUpdateSR(){
        // console.log('opening...')
    },
  },
  mounted() {
    this.getTimeZone();
    // this.get();
  },
})
