var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600px", persistent: "" },
          model: {
            value: _vm.dialogSR,
            callback: function ($$v) {
              _vm.dialogSR = $$v
            },
            expression: "dialogSR",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "v-modal-fixed style-2 " },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline d-flex justify-space-between px-5 py-3 v-modal-header sticky-header",
                },
                [
                  _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(_vm._s(_vm.$t("sr.createScheduleReport"))),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "black--text",
                      on: {
                        click: function ($event) {
                          _vm.dialogSR = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-4 pb-4" },
                [
                  [
                    _c(
                      "v-container",
                      { staticClass: "pa-3 mx-0" },
                      [
                        _c(
                          "v-form",
                          {
                            ref: "createSRFormRef",
                            model: {
                              value: _vm.createSRForm,
                              callback: function ($$v) {
                                _vm.createSRForm = $$v
                              },
                              expression: "createSRForm",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sr.enterReportName"))
                                        ),
                                        _c(
                                          "sup",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        maxlength: 40,
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        placeholder: _vm.$t(
                                          "sr.enterReportName"
                                        ),
                                        rules: [
                                          function (v) {
                                            return (
                                              v != "" ||
                                              _vm.$t(
                                                "createAR.pleaseEnteraValue"
                                              )
                                            )
                                          },
                                          function (v) {
                                            return (
                                              v != 0 ||
                                              _vm.$t(
                                                "createAR.pleaseEnteraValue"
                                              )
                                            )
                                          },
                                          function (v) {
                                            return (
                                              v != null ||
                                              _vm.$t(
                                                "createAR.pleaseEnteraValue"
                                              )
                                            )
                                          },
                                          function (v) {
                                            return (
                                              !/[<>;\\[\]{}&%]/g.test(v) ||
                                              this$1.$t(
                                                "createCampaignRules.specialCharNotAllowed"
                                              )
                                            )
                                          },
                                          function (v) {
                                            return (
                                              !/\s\s/.test(v) ||
                                              this$1.$t(
                                                "createCampaignRules.onlySinglespaceAllowed"
                                              )
                                            )
                                          },
                                          function (v) {
                                            return (
                                              !this$1.existingReportNames.some(
                                                function (s) {
                                                  return (
                                                    s === v.toLowerCase().trim()
                                                  )
                                                }
                                              ) || _vm.$t("sr.reportNameExists")
                                            )
                                          },
                                        ],
                                      },
                                      model: {
                                        value: _vm.reportName,
                                        callback: function ($$v) {
                                          _vm.reportName =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "reportName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-0 d-flex align-baseline" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      " d-flex justify-space-between align-baseline py-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text  d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sr.reportOf")) + " "
                                        ),
                                        _c(
                                          "sup",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "field close-btn d-flex align-center cursor-pointer ml-4",
                                        attrs: {
                                          disabled:
                                            _vm.selectedReportFor === null,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.selectedReportFor = null
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "primary--text",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-window-close")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "primary--text" },
                                          [_vm._v(_vm._s(_vm.$t("kai.clear")))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: " d-flex align-baseline py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        attach: "",
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true,
                                        },
                                        items: _vm.reportFors,
                                        "item-text": "for",
                                        "item-value": "id",
                                        rules: [
                                          function (v) {
                                            return (
                                              v != null ||
                                              _vm.$t("sr.pleaseSelectFor")
                                            )
                                          },
                                          function (v) {
                                            return (
                                              v != 9 ||
                                              _vm.$t("sr.pleaseSelectFor")
                                            )
                                          },
                                        ],
                                      },
                                      on: { change: _vm.forChanged },
                                      model: {
                                        value: _vm.selectedReportFor,
                                        callback: function ($$v) {
                                          _vm.selectedReportFor = $$v
                                        },
                                        expression: "selectedReportFor",
                                      },
                                    }),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-3",
                                        attrs: {
                                          color: "primary",
                                          depressed: "",
                                          medium: "",
                                          disabled:
                                            _vm.selectedReportFor === null ||
                                            _vm.selectedReportFor === 9 ||
                                            _vm.selectedReportFor === 0 ||
                                            _vm.selectedReportFor === 5,
                                        },
                                        on: { click: _vm.choosePop },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("sr.addMore")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.selectedReportFor === 0
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 ",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                multiple: "",
                                                items: _vm.orgList,
                                                "item-text": "orgName",
                                                "item-value": "orgId",
                                                label: _vm.$t("labels.org"),
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                                "return-object": "",
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      v != null ||
                                                      _vm.$t("sr.selectOrg")
                                                    )
                                                  },
                                                  function (v) {
                                                    return (
                                                      v.length > 0 ||
                                                      _vm.$t("sr.selectOrg")
                                                    )
                                                  },
                                                ],
                                              },
                                              on: { change: _vm.orgChanged },
                                              model: {
                                                value: _vm.orgs,
                                                callback: function ($$v) {
                                                  _vm.orgs = $$v
                                                },
                                                expression: "orgs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.selectedReportFor === 7
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                multiple: "",
                                                items: _vm.goalList,
                                                "item-text": "name",
                                                "item-value": "_id",
                                                label: _vm.$t("common.goal"),
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                                "return-object": "",
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      v != null ||
                                                      _vm.$t("sr.selectGoal")
                                                    )
                                                  },
                                                  function (v) {
                                                    return (
                                                      v.length > 0 ||
                                                      _vm.$t("sr.selectGoal")
                                                    )
                                                  },
                                                ],
                                              },
                                              on: { change: _vm.goalChanged },
                                              model: {
                                                value: _vm.goals,
                                                callback: function ($$v) {
                                                  _vm.goals = $$v
                                                },
                                                expression: "goals",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                (_vm.selectedReportFor === 1 ||
                                  _vm.selectedReportFor === 2 ||
                                  _vm.selectedReportFor === 3 ||
                                  _vm.selectedReportFor === 4) &&
                                _vm.filteredGoalList.length > 0
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                multiple: "",
                                                items: _vm.filteredGoalList,
                                                "item-text": "name",
                                                "item-value": "_id",
                                                label: _vm.$t("common.goal"),
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                                "return-object": "",
                                              },
                                              on: { change: _vm.goalChanged },
                                              model: {
                                                value: _vm.goals,
                                                callback: function ($$v) {
                                                  _vm.goals = $$v
                                                },
                                                expression: "goals",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.selectedReportFor === 5
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("label", [_vm._v("App")]),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.appsList,
                                                "item-text": "appName",
                                                "item-value": "adamId",
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      _vm.selectedReportFor !==
                                                        5 ||
                                                      v != null ||
                                                      "Select app"
                                                    )
                                                  },
                                                ],
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function (data) {
                                                      return [
                                                        [
                                                          _c("v-img", {
                                                            staticClass:
                                                              "rounded-lg",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              "lazy-src":
                                                                data.item
                                                                  .appIcon,
                                                              "max-width": "30",
                                                              "max-height":
                                                                "30",
                                                              "min-width": "20",
                                                              "min-height":
                                                                "20",
                                                              src:
                                                                data.item
                                                                  .appIcon,
                                                            },
                                                          }),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "ml-2 ",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .appName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1377571668
                                              ),
                                              model: {
                                                value: _vm.impShareFields.app,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.impShareFields,
                                                    "app",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "impShareFields.app",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("label", [_vm._v("Granularity")]),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              width: "12",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "greyxdark--text",
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4293097711
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Organize your report data by day or week."
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.granularityList,
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      _vm.selectedReportFor !==
                                                        5 ||
                                                      v != null ||
                                                      "Select granularity"
                                                    )
                                                  },
                                                ],
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.impShareFields
                                                    .granularity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.impShareFields,
                                                    "granularity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "impShareFields.granularity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("label", [
                                              _vm._v("Time Period"),
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.periodListFull,
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                rules: [
                                                  function (v) {
                                                    return (
                                                      _vm.selectedReportFor !==
                                                        5 ||
                                                      v != null ||
                                                      "Select time period"
                                                    )
                                                  },
                                                ],
                                                "menu-props": {
                                                  bottom: true,
                                                  offsetY: true,
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.impShareFields.period,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.impShareFields,
                                                    "period",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "impShareFields.period",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.selectedReportFor === 5 &&
                                    _vm.impShareFields.period === "CUSTOM"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: {
                                              cols: "12",
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "menu",
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "return-value":
                                                        _vm.ruleRangePicker,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                      "min-width": "auto",
                                                    },
                                                    on: {
                                                      "update:returnValue": function (
                                                        $event
                                                      ) {
                                                        _vm.ruleRangePicker = $event
                                                      },
                                                      "update:return-value": function (
                                                        $event
                                                      ) {
                                                        _vm.ruleRangePicker = $event
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Date Range"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "v-date-picker-cs w-sm-100 mx-0",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "revert",
                                                                      },
                                                                      attrs: {
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        readonly:
                                                                          "",
                                                                        rules: [
                                                                          function (
                                                                            v
                                                                          ) {
                                                                            return (
                                                                              _vm
                                                                                .impShareFields
                                                                                .period !==
                                                                                "CUSTOM" ||
                                                                              !!v ||
                                                                              "Select date range"
                                                                            )
                                                                          },
                                                                        ],
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.ruleDateRangeText,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.ruleDateRangeText = $$v
                                                                        },
                                                                        expression:
                                                                          "ruleDateRangeText",
                                                                      },
                                                                    },
                                                                    "v-text-field",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      326994760
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.ruleRangePicker,
                                                      callback: function ($$v) {
                                                        _vm.ruleRangePicker = $$v
                                                      },
                                                      expression:
                                                        "ruleRangePicker",
                                                    },
                                                  },
                                                  [
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                flex: "auto",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    max:
                                                                      _vm.maxDate,
                                                                    "no-title":
                                                                      "",
                                                                    scrollable:
                                                                      "",
                                                                    range: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.ruleDateRangelModel,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.ruleDateRangelModel = $$v
                                                                    },
                                                                    expression:
                                                                      "ruleDateRangelModel",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        color:
                                                                          "grey",
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          _vm.ruleRangePicker = false
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createAR.cancel"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.ruleDateRangelModel &&
                                                                          _vm
                                                                            .ruleDateRangelModel
                                                                            .length !==
                                                                            2,
                                                                        text:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.applyRuleDateRange()
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "kai.ok"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        text:
                                                                          "",
                                                                        color:
                                                                          "secondary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.clearRuleDateRange,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "kai.clear"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          lg: "12",
                                          md: "12",
                                          sm: "12",
                                          cols: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field mb-4" },
                                          [
                                            _c("label", [_vm._v("Countries")]),
                                            _c("v-autocomplete", {
                                              attrs: {
                                                clearable: "",
                                                multiple: "",
                                                outlined: "",
                                                dense: "",
                                                attach: "",
                                                chips: "",
                                                "small-chips": "",
                                                "deletable-chips": "",
                                                items: _vm.appCountries,
                                                "item-text": "Name",
                                                "item-value": "Code",
                                                "search-input": _vm.searchInput,
                                              },
                                              on: {
                                                "update:searchInput": function (
                                                  $event
                                                ) {
                                                  _vm.searchInput = $event
                                                },
                                                "update:search-input": function (
                                                  $event
                                                ) {
                                                  _vm.searchInput = $event
                                                },
                                                change: function ($event) {
                                                  _vm.searchInput = null
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function (data) {
                                                      return [
                                                        [
                                                          _c("country-flag", {
                                                            attrs: {
                                                              country: data.item.Code.toLowerCase(),
                                                            },
                                                          }),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .Name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                395861938
                                              ),
                                              model: {
                                                value:
                                                  _vm.impShareFields.countries,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.impShareFields,
                                                    "countries",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "impShareFields.countries",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 5
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pb-2 pt-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "black--text d-flex" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("users.rowPerPages")
                                              ) + " "
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  width: "12",
                                                  color: "primarydark",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "greyxdark--text ml-1",
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-information-outline"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3509578930
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Filter the keywords delivered in the report by the top keywords by spend in your selected time period."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          lg: "12",
                                          md: "12",
                                          sm: "12",
                                          cols: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "v-sel-custom v-sel-custom-rule",
                                              attrs: {
                                                items: _vm.reportLimit,
                                                attach: "",
                                                outlined: "",
                                                dense: "",
                                                "menu-props": {
                                                  top: true,
                                                  offsetY: true,
                                                },
                                                placeholder: _vm.$t(
                                                  "users.rowPerPages"
                                                ),
                                              },
                                              model: {
                                                value: _vm.reportLimitSelected,
                                                callback: function ($$v) {
                                                  _vm.reportLimitSelected = $$v
                                                },
                                                expression:
                                                  "reportLimitSelected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 0
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.orgs.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "sr.orgsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.orgs,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item.orgId,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.orgName) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 1
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.apps.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createAR.appsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.apps,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item.adamId,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.appName) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 2
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.campaigns.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createAR.campaignsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.campaigns,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item.campaignId,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.campName) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 3
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.adgroups.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createAR.adgroupsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.adgroups,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item.adgroupId,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.adgroupName
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 4
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.keywords.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createAR.KeywordsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.keywords,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item.keywordId,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.keywordName
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor === 7
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center pt-0 pb-5",
                                        attrs: { cols: "12", sm: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _vm.goals.length > 0
                                              ? _c(
                                                  "h5",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "sr.goalsSelected"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.goals,
                                              function (item, idx) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key: item._id,
                                                    staticStyle: {
                                                      margin: "0px 10px 5px",
                                                    },
                                                    attrs: {
                                                      color: "#eee",
                                                      "text-color": "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          color: "primary",
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItemfromChosen(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-close ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedReportFor !== 5
                              ? _c(
                                  "v-row",
                                  [
                                    _vm.selectedReportFor !== 5
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-2 mb-2",
                                            attrs: {
                                              cols: "12",
                                              xl: "12",
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "black--text  d-flex",
                                              },
                                              [
                                                _vm._v(" Time Period "),
                                                _c(
                                                  "sup",
                                                  { staticClass: "red--text" },
                                                  [_vm._v("*")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  " align-center  flex-wrap w-100",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    attach: "",
                                                    "menu-props": {
                                                      bottom: true,
                                                      offsetY: true,
                                                    },
                                                    items: _vm.dateSplitRange,
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                  },
                                                  model: {
                                                    value: _vm.reportTimePeriod,
                                                    callback: function ($$v) {
                                                      _vm.reportTimePeriod = $$v
                                                    },
                                                    expression:
                                                      "reportTimePeriod",
                                                  },
                                                }),
                                                _vm.reportTimePeriod === 0
                                                  ? _c(
                                                      "v-menu",
                                                      {
                                                        ref: "menu",
                                                        staticClass:
                                                          "overview-menu date-picker",
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "return-value":
                                                            _vm.rangePicker,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": "",
                                                          "min-width": "auto",
                                                          right: "",
                                                        },
                                                        on: {
                                                          "update:returnValue": function (
                                                            $event
                                                          ) {
                                                            _vm.rangePicker = $event
                                                          },
                                                          "update:return-value": function (
                                                            $event
                                                          ) {
                                                            _vm.rangePicker = $event
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "v-date-picker-cs w-sm-100 w-100",
                                                                          staticStyle: {
                                                                            "font-size":
                                                                              "revert",
                                                                          },
                                                                          attrs: {
                                                                            "prepend-icon":
                                                                              "mdi-calendar",
                                                                            readonly:
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.filterDateRangeText,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.filterDateRangeText = $$v
                                                                            },
                                                                            expression:
                                                                              "filterDateRangeText",
                                                                          },
                                                                        },
                                                                        "v-text-field",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          162034342
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.rangePicker,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.rangePicker = $$v
                                                          },
                                                          expression:
                                                            "rangePicker",
                                                        },
                                                      },
                                                      [
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    flex:
                                                                      "auto",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "flex-container column mt-3",
                                                                      staticStyle: {
                                                                        "list-style-type":
                                                                          "none",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "TODAY"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.today"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "YESTERDAY"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.yesterday"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "WEEKTODATE"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.weekToDate"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "LASTWEEK"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.lastWeek"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "LASTSEVENDAYS"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.lastSevenDays"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "MONTHTODATE"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.monthToDate"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "LASTMONTH"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.lastMonth"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "li",
                                                                        {
                                                                          staticClass:
                                                                            "flex-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ma-1",
                                                                              attrs: {
                                                                                label:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.selectPreset(
                                                                                    "LASTTHIRTYDAY"
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "dateRangePresets.lastThirtyDays"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    flex:
                                                                      "auto",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-date-picker",
                                                                    {
                                                                      attrs: {
                                                                        max:
                                                                          _vm.maxDate,
                                                                        "no-title":
                                                                          "",
                                                                        scrollable:
                                                                          "",
                                                                        range:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.filterDateRangeModel,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.filterDateRangeModel = $$v
                                                                        },
                                                                        expression:
                                                                          "filterDateRangeModel",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            text:
                                                                              "",
                                                                            color:
                                                                              "grey",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              _vm.rangePicker = false
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cancel"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm.filterDateRangeModel &&
                                                                              _vm
                                                                                .filterDateRangeModel
                                                                                .length !==
                                                                                2,
                                                                            text:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.changePeriod,
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "OK"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _c("v-checkbox", {
                                                  staticClass: "mt-0",
                                                  attrs: {
                                                    label:
                                                      "Include Date Column",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.includeDateColumn,
                                                    callback: function ($$v) {
                                                      _vm.includeDateColumn = $$v
                                                    },
                                                    expression:
                                                      "includeDateColumn",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              { staticClass: "mt-0 d-flex align-baseline" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text  d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sr.scheduleReport1")) +
                                            " "
                                        ),
                                        _c(
                                          "sup",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        attach: "",
                                        "menu-props": {
                                          top: true,
                                          offsetY: true,
                                        },
                                        label: _vm.$t("sr.pleaseSelectType"),
                                        items: _vm.repeatTypes,
                                        "item-text": "type",
                                        "item-value": "id",
                                        rules: [
                                          function (v) {
                                            return (
                                              v != null ||
                                              _vm.$t("sr.pleaseSelectType")
                                            )
                                          },
                                        ],
                                      },
                                      on: { change: _vm.repeatTypeChanged },
                                      model: {
                                        value: _vm.selectedRepeatType,
                                        callback: function ($$v) {
                                          _vm.selectedRepeatType = $$v
                                        },
                                        expression: "selectedRepeatType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                !(
                                  _vm.selectedReportFor === 5 &&
                                  _vm.selectedRepeatType === 99
                                )
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          lg: "12",
                                          md: "12",
                                          sm: "12",
                                          cols: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "pa-0 pb-5 d-flex align-center",
                                          },
                                          _vm._l(_vm.days, function (day) {
                                            return _c(
                                              "button",
                                              {
                                                class: day.status
                                                  ? "day selected-day d-flex align-center justify-center mr-3"
                                                  : "day d-flex align-center justify-center mr-3",
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    _vm.selectedRepeatType ===
                                                      30 ||
                                                    _vm.selectedRepeatType ===
                                                      1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    day.status = !day.status
                                                    _vm.dayClicked(day)
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(day.value) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text  d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sr.selectTime")) + " "
                                        ),
                                        _c(
                                          "sup",
                                          { staticClass: "red--text" },
                                          [_vm._v("*")]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "greylight--text",
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " ( " +
                                                            _vm._s(
                                                              _vm.localTime
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.timeZone
                                                            ) +
                                                            " )"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Time zone when the report will be sent. Data within the report will be UTC"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                false
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          lg: "12",
                                          md: "12",
                                          sm: "12",
                                          cols: "12",
                                        },
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            solo: "",
                                            clearable:
                                              _vm.selectedRepeatType === 0 ||
                                              _vm.selectedRepeatType === 1,
                                            multiple:
                                              _vm.selectedRepeatType === 0 ||
                                              _vm.selectedRepeatType === 1,
                                            chips: "",
                                            "small-chips": "",
                                            "deletable-chips": "",
                                            attach: "",
                                            "menu-props": {
                                              top: true,
                                              offsetY: true,
                                            },
                                            label: _vm.$t("sr.pleaseSelectDay"),
                                            items: _vm.reportDays,
                                            "item-text": "day",
                                            "item-value": "id",
                                            rules: [
                                              function (v) {
                                                return (
                                                  v != null ||
                                                  _vm.$t("sr.pleaseSelectDay")
                                                )
                                              },
                                              function (v) {
                                                if (
                                                  v &&
                                                  typeof v === "object"
                                                ) {
                                                  return (
                                                    v.length > 0 ||
                                                    _vm.$t("sr.pleaseSelectDay")
                                                  )
                                                } else {
                                                  return true
                                                }
                                              },
                                            ],
                                            disabled:
                                              _vm.selectedRepeatType === 30 ||
                                              _vm.selectedRepeatType === 1,
                                          },
                                          on: { change: _vm.clicked },
                                          model: {
                                            value: _vm.selectedReportDay,
                                            callback: function ($$v) {
                                              _vm.selectedReportDay = $$v
                                            },
                                            expression: "selectedReportDay",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        attach: "",
                                        "menu-props": {
                                          top: true,
                                          offsetY: true,
                                        },
                                        label: _vm.$t("sr.pleaseSelectTime"),
                                        items: _vm.reportTimes,
                                        "item-text": "time",
                                        "item-value": "id",
                                        rules: [
                                          function (v) {
                                            return (
                                              v != null ||
                                              _vm.$t("sr.pleaseSelectTime")
                                            )
                                          },
                                          function (v) {
                                            return (
                                              (v > -1 && v < 24) ||
                                              _vm.$t("sr.pleaseSelectTime")
                                            )
                                          },
                                        ],
                                      },
                                      on: { change: _vm.clicked },
                                      model: {
                                        value: _vm.selectedReportTime,
                                        callback: function ($$v) {
                                          _vm.selectedReportTime = $$v
                                        },
                                        expression: "selectedReportTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-2 pt-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("users.enterEmail"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "v-sel-custom v-sel-email-rule",
                                          attrs: {
                                            items: _vm.emailList,
                                            multiple: "",
                                            outlined: "",
                                            attach: "",
                                            clearable: "",
                                            chips: "",
                                            "deletable-chips": "",
                                            dense: "",
                                            placeholder: _vm.$t(
                                              "users.enterEmail"
                                            ),
                                            rules:
                                              _vm.externalEmails.length > 0
                                                ? []
                                                : _vm.emailRules,
                                            "no-data-text": _vm.$t(
                                              "dropdowns.noData"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.emailSelectionChanged()
                                            },
                                          },
                                          model: {
                                            value: _vm.emails,
                                            callback: function ($$v) {
                                              _vm.emails = $$v
                                            },
                                            expression: "emails",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c("v-combobox", {
                                      ref: "emailSelectRef",
                                      staticClass: "v-sel-custom",
                                      attrs: {
                                        multiple: "",
                                        label: _vm.$t("createAR.addOtherEmail"),
                                        outlined: "",
                                        chips: "",
                                        clearable: "",
                                        "deletable-chips": "",
                                        dense: "",
                                        rules:
                                          _vm.externalEmails.length > 0
                                            ? _vm.csvEmailRules
                                            : [],
                                      },
                                      on: { change: _vm.onModelValueChange },
                                      model: {
                                        value: _vm.externalEmails,
                                        callback: function ($$v) {
                                          _vm.externalEmails = $$v
                                        },
                                        expression: "externalEmails",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-2 pt-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "black--text d-flex" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("sr.selectSlackChannel")
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _c("v-select", {
                                          staticClass: "v-sel-custom",
                                          attrs: {
                                            items: _vm.slackChannelList,
                                            "item-value": "IntegrationID",
                                            "item-text": "channel",
                                            attach: "",
                                            outlined: "",
                                            chips: "",
                                            "deletable-chips": "",
                                            clearable: "",
                                            dense: "",
                                            multiple: "",
                                            "menu-props": {
                                              top: true,
                                              offsetY: true,
                                            },
                                            "no-data-text": _vm.$t(
                                              "dropdowns.noData"
                                            ),
                                          },
                                          model: {
                                            value: _vm.slacks,
                                            callback: function ($$v) {
                                              _vm.slacks = $$v
                                            },
                                            expression: "slacks",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-end mt-0 pa-5 sticky-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "grey",
                        outlined: "",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogSR = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "127",
                        color: "primary",
                        depressed: "",
                        disabled:
                          !_vm.createSRForm ||
                          (_vm.selectedReportFor === 0 &&
                            _vm.orgs.length === 0) ||
                          (_vm.selectedReportFor === 1 &&
                            _vm.apps.length === 0) ||
                          (_vm.selectedReportFor === 2 &&
                            _vm.campaigns.length === 0) ||
                          (_vm.selectedReportFor === 3 &&
                            _vm.adgroups.length === 0) ||
                          (_vm.selectedReportFor === 4 &&
                            _vm.keywords.length === 0) ||
                          (_vm.selectedReportFor === 7 &&
                            _vm.goals.length === 0),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.askConfirm()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectCampaign", {
        attrs: { openSelectCampaign: _vm.chooseCampaigns },
        on: {
          onCampaignSelection: function ($event) {
            return _vm.chosenCampaigns($event)
          },
        },
      }),
      _c("SelectAdGroup", {
        attrs: { settings: _vm.chooseAdgroups },
        on: {
          onAdgroupSelection: function ($event) {
            return _vm.chosenAdgroups($event)
          },
        },
      }),
      _c("SelectApps", {
        attrs: { selectApps: _vm.chooseApps },
        on: {
          onAppSelection: function ($event) {
            return _vm.chosenApps($event)
          },
        },
      }),
      _c("SelectKeywords", {
        attrs: { selectKeywordData: _vm.chooseKeywords },
        on: {
          onkeywordSelection: function ($event) {
            return _vm.chosenKeywords($event)
          },
        },
      }),
      _c("DecisionAlert", {
        attrs: {
          DecisionAlert: _vm.decisionAlertFlag,
          DecisionAlertTitle: _vm.decisionAlertTitle,
          DecisionAlertIcon: _vm.decisionAlertIcon,
          DecisionAlertMessage: _vm.decisionAlertMessage,
          DecisionAlertData: _vm.decisionAlertData,
        },
        on: {
          emitDecisionAlertCancelClicked: function ($event) {
            _vm.decisionAlertFlag = false
          },
          emitDecisionAlertOkClicked: function ($event) {
            return _vm.decisionOkAction($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }